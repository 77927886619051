import React, {FC, useEffect, useRef, useState} from "react";
import cls from "./customTopBar.module.scss"
import Link from "next/link";
import Image from "next/image"
import {DropDownArrow} from "../../../svg";
import {ICustomTopBar} from "./MainLayout";
import {domainUrl} from "../../../helper";
import {useRouter} from "next/router";
import CustomCurrDropDown from "./CustomCurrDropDown";
import {mobileMenuClose} from "../../../store/mobile-menu";

const CustomTopBar: FC<ICustomTopBar> = (
    {
        telephone,
        dbName,
        locales = [],
        currencies: [{
            code: selectedCurr = "",
            symbol: selectedSymbol = ""
        } = {}, ...currencies] = [],
        selectedLocaleImg
    }
) => {
    const currRef = useRef<any>(null)
    const langRef = useRef<any>(null)

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (langRef && langRef.current && !langRef.current.contains(e.target)) {
                setOpenLang({langOpened: false, langClassName: ""})
            }
        }

        document.addEventListener("mousedown", handleClick)
        return () => document.removeEventListener("mousedown", handleClick)
    }, [currRef, langRef])

    const [
        {langOpened, langClassName},
        setOpenLang
    ] = useState({langOpened: false, langClassName: ""})

    const {asPath, ...router} = useRouter();

    const handleClassTypes = {"false": ["", "menu-none"], "true": ["rotT_fms", "menu-block"]};

    const handleLangClick = () => {
        setOpenLang(({langOpened: prevOpen}) => {
            return {
                langOpened: !prevOpen,
                langClassName: handleClassTypes[`${!prevOpen}`][0]
            }
        })
    }

    const handleLangItemClick = (localeCode: string) => {
        router.push(asPath, asPath, {locale: localeCode}).then(r => r);
    }

    const hasDropDown = {
        true: {
            arrow: <DropDownArrow className={cls[langClassName]}/>,
            dropdown: <div className={cls[handleClassTypes[`${langOpened}`][1]]}>
                <div className={cls.menu}>
                    {locales?.map(({code, id, name, locale_image}) => (
                        <div key={id} className={cls.menu_item}
                             onClick={() => handleLangItemClick(code)}>
                            <div className={cls.menu__icon}>
                                <img src={domainUrl(`${dbName}${locale_image}`)} alt="Language" width={23} height={18} loading="lazy"/>
                            </div>
                            <p className={cls.menu__title}>
                                {name}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        },
        false: {
            arrow: null,
            dropdown: null
        }
    }

    const handleCurrencyDropdown = {
        true: <CustomCurrDropDown selectedCurr={selectedCurr} selectedSymbol={selectedSymbol} currencies={currencies}/>,
        false: null
    }

    return (
        <div className={cls["top-bar"]}>
            <div className="container">
                <div className={cls["top-bar-main"]}>
                    <Link href={`tel:${telephone}`} prefetch={true}>{telephone}</Link>
                    <div className={cls.topbar_buttons}>
                        {handleCurrencyDropdown[`${!!currencies.length}`]}
                        <div id="forLanguage" className={cls["language-button"]} onClick={handleLangClick}
                             ref={langRef}>
                            <img src={domainUrl(`${dbName}${selectedLocaleImg}`)} alt="Language" width={23} height={18}
                                 loading="lazy"/>
                            {hasDropDown[`${locales.length > 0}`].arrow}
                            {hasDropDown[`${locales.length > 0}`].dropdown}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CustomTopBar;
