import React, {FC, JSX, useEffect, useState} from "react"
import Link from "next/link";
import {useSelector} from "react-redux";
import cls from "../customHeader.module.scss"
import {ICartItemType, IState} from "../../../../../types/state";
import {ICurrencies} from "../../MainLayout";
import {TranslationsType} from "../../../../../types/homePageTypes";

interface ICustomCartDropDown {
    cart: { items: Array<ICartItemType>, grand_total: string; sub_total: string };
    items: Array<JSX.Element>;
    isCheckAllow: () => void;
    selectedRate: ICurrencies;
    translationsData: TranslationsType;
    allowCheckoutValue: string
}

const CustomCartDropDown: FC<ICustomCartDropDown> = (
    {
        cart,
        items,
        isCheckAllow,
        selectedRate: {symbol: selectedCurrSymbol} = {},
        translationsData,
        allowCheckoutValue
    }
) => {
    const customerToken = useSelector((state: IState) => state.customer.token);
    const classNameScrollHandle = {
        "true": cls["dropcart__products-list_scroll"],
        "false": cls["dropcart__products-list"]
    }
    const guestHrefHandle = {
        "true": {
            "true": "",
            "false": "/checkout"
        },
        "false": {
            "true": "/checkout",
            "false": "/checkout"
        }
    }

    return (
        <div className={cls.dropcart}>
            <div className={classNameScrollHandle[`${cart.items.length > 3}`]}>
                {items}
            </div>
            <div className={cls.dropcart__totals}>
                <table>
                    <tbody>
                    <tr>
                        <th>
                            {translationsData?.Total}{" "}
                        </th>
                        <td>
                            {/*{selectedCurrSymbol}*/}
                            {/*{" "}*/}
                            {/*{Number(cart.grand_total).toFixed(2)}*/}
                            {cart.sub_total}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={cls.dropcart__buttons}>
                <Link href="/cart">
                    <a className={`btn btn-orange ${cls["rounded-pills"]}`}>
                        {translationsData?.Cart}
                    </a>
                </Link>
                <Link href={guestHrefHandle[`${parseFloat(allowCheckoutValue) === 0}`][`${customerToken === ""}`]}>
                    <a
                        className={`btn btn-orange ${cls["rounded-pills"]} ${cls["dropcart__buttons-link"]}`}
                        onClick={isCheckAllow}
                    >
                        {translationsData?.Checkout}{" "}
                    </a>
                </Link>
            </div>
        </div>
    )
};

export default CustomCartDropDown