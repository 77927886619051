import cls from "./customHeader.module.scss"
import React, {FC, Fragment, JSX, useEffect, useRef, useState} from "react";
import {IFirstHeader} from "../MainLayout";
import Link from "next/link";
import {domainUrl} from "../../../../helper";
import Script from "next/script";
import {useRouter} from "next/router";
import CustomItemList from "./CustomItemList";
import {ArrowRoundedDown9x6Svg, Cross20Svg, VektorMenu} from "../../../../svg";
import {mobileMenuOpen} from "../../../../store/mobile-menu";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../../types/state";

const showPost: (arg: string) => Record<string, JSX.Element> = (title) => {
    return {
        BlogActive: <div className={cls.header_nav_menu_item}>
            <Link href="/posts" prefetch={true}>{title}</Link>
        </div>,
        BlogInActive: <></>
    }
};

const schemaSiteHeader = (dbName: string, asPath: string) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "ItemList",
        "name": "Header Items",
        "description": "contact, change currency and language",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Telephone"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Currency"
            },
            {
                "@type": "ListItem",
                "position": 3,
                "name": "Language"
            },
        ],
        url: domainUrl(dbName + asPath)
    }
}

const FirstSection: FC<IFirstHeader> = (
    {
        logoPath,
        dbName,
        domain,
        menus,
        hasBlog,
        selectedLocaleImg,
        translationsData,
        locales = [],
        cacheControlVersion
    }
) => {
    const router = useRouter()
    const {asPath} = router
    const schemaHeader = schemaSiteHeader(dbName, asPath)
    const [
        {langOpened, langClassName},
        setOpenLang
    ] = useState({langOpened: false, langClassName: ""})
    const mobileMenu = useSelector((state: IState) => state.mobileMenu.open)
    const langRef = useRef<any>(null)
    const dispatch = useDispatch()

    const handleClassTypes = {"false": ["", "menu-none"], "true": ["rotT_fms", "menu-block"]};

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (langRef && langRef.current && !langRef.current.contains(e.target)) {
                setOpenLang({langOpened: false, langClassName: ""})
            }
        }

        document.addEventListener("mousedown", handleClick)

        return () => document.removeEventListener("mousedown", handleClick)
    }, [langRef])

    const handleLangClick = () => {
        setOpenLang(({langOpened: prevOpen}) => {
            return {
                langOpened: !prevOpen,
                langClassName: handleClassTypes[`${!prevOpen}`][0]
            }
        })
    }

    const mobileMenuIcon = {
        true: <VektorMenu className={cls.menu_icon}/>,
        false: <Cross20Svg/>
    }

    const handleLangItemClick = (localeCode: string) => {
        router.push(asPath, asPath, {locale: localeCode}).then(r => r);
    }

    const hasDropDown = {
        true: <div className={cls[handleClassTypes[`${langOpened}`][1]]}>
            <div className={cls.menu}>
                {locales?.map(({code, id, name, locale_image}) => {
                    return <div key={id} className={cls.menu_item}
                                onClick={() => handleLangItemClick(code)}>
                        <div className={cls.menu__icon}>
                            <img
                                src={domainUrl(`${dbName}${locale_image}`)}
                                alt="Language"
                                width="30"
                                height="24"
                                loading="lazy"
                            />
                        </div>
                    </div>
                })}
            </div>
        </div>,
        false: null
    }

    return (
        <Fragment>
            <Script
                id={schemaHeader.name}
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schemaHeader)}}
                defer={true}
            />
            <div className="container">
                <div className={cls.first_section}>
                    <button
                        type="button"
                        className={cls["mobile-header__menu-button"]}
                        onClick={() => dispatch(mobileMenuOpen())}
                        aria-label="MobileHeaderBtn"
                    >
                        {mobileMenuIcon[`${!mobileMenu}`]}
                    </button>
                    <div className={cls.logo_div}>
                        <Link href="/" prefetch={true}>
                            <a>
                                <img
                                    src={domainUrl(`${dbName}/${logoPath}?v=${cacheControlVersion}`)}
                                    alt="Logo"
                                    // loading="eager"
                                    loading="lazy"
                                    width="200"
                                    height="40"
                                />
                            </a>
                        </Link>
                    </div>
                    <div
                        id="forLanguage"
                        className={cls["language-button"]}
                        onClick={handleLangClick}
                        ref={langRef}
                    >
                        <img
                            src={domainUrl(`${dbName}${selectedLocaleImg}`)}
                            alt="Language"
                            // loading="eager"
                            loading="lazy"
                            width="30"
                            height="24"
                        />
                        {hasDropDown[`${locales.length > 0}`]}
                    </div>

                    <div className={cls.header_nav_menu}>
                        {menus?.map(({
                                         name = "",
                                         children = [],
                                         url_key = "",
                                         target
                                     }, index: number) => {

                            const withSubmenu = {
                                true: {
                                    class: `${cls.header_nav_menu_item} ${cls.with_submenu}`,
                                    arrow: <ArrowRoundedDown9x6Svg/>,
                                    menu: <CustomItemList items={children} />
                                },
                                false: {
                                    class: cls.header_nav_menu_item,
                                    arrow: null,
                                    menu: null
                                },
                            }

                            return <div className={withSubmenu[`${!!children.length}`].class} key={index}>
                                <Link href={url_key} prefetch={true}>
                                    <a {...target}>
                                        {name}
                                        {withSubmenu[`${!!children.length}`].arrow}
                                    </a>
                                </Link>
                                {withSubmenu[`${!!children.length}`].menu}
                            </div>
                        })}
                        {showPost(translationsData?.Posts)[hasBlog]}
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default FirstSection;
