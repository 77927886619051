import React, {ChangeEvent, Dispatch, RefObject, SetStateAction, useEffect} from "react";
import queryString from "query-string";
import {Cross20Svg, SearchssSvg} from "../../../../../svg";
import {useRouter} from "next/router";
import cls from "./customSearch.module.scss"
import {IProduct, TranslationsType} from "../../../../../types/homePageTypes";

interface ISearchComponent {
    suggestedProducts: Array<IProduct>;
    setQuery: Dispatch<SetStateAction<string>>;
    wrapper: RefObject<HTMLInputElement>;
    handleFocus: () => void;
    handleKeyDown: (event: { key: string, preventDefault: () => void }) => Promise<void>;
    handleSearchButt: () => Promise<void>;
    handleChangeQuery: (event: ChangeEvent<HTMLInputElement>) => void;
    handleChangeQueryReq: (event: ChangeEvent<HTMLInputElement>) => void;
    close: () => void;
    translationsData: TranslationsType,
}

const SearchComponent: React.FC<ISearchComponent> = (
    {
        setQuery,
        wrapper,
        handleFocus,
        handleKeyDown,
        suggestedProducts,
        handleSearchButt,
        handleChangeQuery,
        handleChangeQueryReq,
        close,
        translationsData,
    }
) => {

    const {asPath: location, query: {search: querySearch = ""} = {}} = useRouter();
    useEffect(() => {
        const {search} = queryString.parse(window.location.search);
        const hasSearch = {
            "true": () => setQuery(search as string),
            "false": () => {
            }
        }
        hasSearch[`${!!search}`]()
    }, [location]);

    useEffect(() => {
        const onGlobalClick = (event: { target: React.JSX.Element }) => {
            if (wrapper.current && !wrapper.current.contains(event.target as unknown as Node)) {
                close();
            }
        };
        document.addEventListener("mousedown", onGlobalClick as unknown as (this: Document, ev: MouseEvent) => void);
        return () => document.removeEventListener("mousedown", onGlobalClick as unknown as (this: Document, ev: MouseEvent) => void);
    }, [close]);

    const handleClick = () => {
        return {
            "true": async () => {
                await handleSearchButt();
                setQuery("")
            },
            "false": () => {
            }
        }
    }

    return (
        <div className={cls.search__form}>
            <input
                // onBlur={handleChangeQuery}
                onChange={(event) => {
                    handleChangeQuery(event)
                    handleChangeQueryReq(event)
                }}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                value={queryString.parse(querySearch as string).search as string}
                className={cls.search__input}
                name="search"
                placeholder={translationsData?.SearchLookingFor}
                aria-label="Site search"
                type="text"
                autoComplete="off"
            />
            <button
                className={`${cls.search__button} ${cls["search__button--type--close"]}`}
                type="button"
                onClick={close}
            >
                <Cross20Svg/>
            </button>
            <button
                className={`${cls.search__button} ${cls["search__button--type--submit"]}`}
                aria-label="CustomSearch"
                onClick={() => handleClick()[`${!!suggestedProducts[0]}`]}
            >
                <SearchssSvg/>
            </button>
        </div>
    )
};

export default SearchComponent;